import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import './Layout.scss'

const Layout = ({ children }) => (
  <>
    <Helmet title="Seinpost" meta={[{ name: 'robots', content: 'noindex' }]}>
      <html lang="nl" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link rel="icon" type="image/png" sizes="512x512" href="/favicon.png" />
    </Helmet>
    <div className="wrapper">{children}</div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
