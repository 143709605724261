import React from 'react'
import { Link, graphql } from 'gatsby'

import Entry from '../components/Entry'
import Layout from '../components/Layout'

export default ({ data }) => {
  const mostRecent = data.recentPost.edges[0].node
  return (
    <Layout>
      <header className="index">
        <h1>
          <Link to="/">Seinpost</Link>
        </h1>
      </header>
      <main className="overview">
        <Entry data={mostRecent} mostRecent />
        {data.nextPosts.edges.map(({ node }) => (
          <Entry key={node.id} data={node} />
        ))}
      </main>
      <footer>
        <div className="ciao">A presto!</div>
      </footer>
    </Layout>
  )
}

export const query = graphql`
  query {
    recentPost: allMarkdownRemark(
      limit: 1
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...Posts
          frontmatter {
            cover {
              childImageSharp {
                fluid(maxWidth: 1096, maxHeight: 390, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    nextPosts: allMarkdownRemark(
      skip: 1
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...Posts
          frontmatter {
            cover {
              childImageSharp {
                fluid(maxWidth: 542, maxHeight: 329, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }

  fragment Posts on MarkdownRemark {
    id
    frontmatter {
      title
      dutchDate: date(formatString: "D MMMM YYYY", locale: "nl")
      date
    }
    fields {
      slug
    }
  }
`
