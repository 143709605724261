import React from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-image'

const Entry = ({ data, mostRecent }) => (
  <>
    <Link to={data.fields.slug}>
      <Image
        fluid={{
          ...data.frontmatter.cover.childImageSharp.fluid,
          sizes: mostRecent
            ? `(max-width: 1080px) calc(100vw-24px), 1080px` // Sizes for image of most recent post
            : `(max-width: 640px) calc(100vw-24px), (max-width: 1080px) calc(50vw-18px), 534px`, // Sizes for older posts
        }}
        placeholderClassName="placeholder"
      />
      <div className="info">
        <time dateTime={data.frontmatter.date}>
          {data.frontmatter.dutchDate}
        </time>
        <h2>{data.frontmatter.title}</h2>
      </div>
    </Link>
  </>
)

export default Entry
